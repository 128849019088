<template>
    <ion-page>
        <ion-content id="main-content" :fullscreen="true">
            <div class="container">
                <h1>Settings</h1>
                <span class="subtitle">Adjust your station’s settings below:</span>
                <Button text="Logout" variant="secondary" @click="$router.push({ name: 'Logout' })" id="logoutBtn"/>
                <Button text="Change location" variant="secondary"  @click="$router.push({ name: 'ChangeLocation'})"/>
            </div>
        </ion-content>

        <Footer />
    </ion-page>
</template>

<script>
import ViewMixin from '@/mixins/views';
import { IonContent, IonPage } from '@ionic/vue';
import { mapState } from 'vuex';

import Footer from '@/components/layout/Footer';
import Button from '@/components/shared/buttons/Button.vue';

export default {
 name: 'Settings',

    components: {
      IonContent,
      IonPage,
      Button,
      Footer,
    },
    mixins:[ViewMixin],
    ionViewDidEnter(){
      this.incrementComponentKeyCounter()
    },
     computed: {
      ...mapState({
        location: (state) => state.locations.current
      })
    },
}
</script>

<style scoped lang="scss">
    ion-content {
    --padding-top:60px;
    color: rgba(24, 31, 54, 1);
    }
</style>